import React from "react"
import { useInView, InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import styled from "styled-components"
import { scroller } from "react-scroll"

import Title from "./../../components/Title"
import TitleWithButton from "./../../components/TitleWithButton"
import ButtonCircle from "./../../components/Buttons/ButtonCircle"
import VerticalSections from "./../../components/VerticalSections/VerticalSections"
import AboutUsps from "./AboutUsps"
import SectionBanner from "./../SectionBanner"

const Wrapper = styled(motion.section)`
    position: relative;

    padding: 8rem 0 1.5rem 0;

    ${props => props.theme.below.desktop`
        .title-wrapper {
            padding: 0;
        }
    `}

    ${props => props.theme.above.desktop`
        padding: 20rem 0 6rem 0;
    `}
`

const TitleWithButtonWrapper = styled.div`
    text-align: center;

    .title-wrapper {
        margin-bottom: 2.5rem;
    }

    .button-circle {
        display: inline-block;
    }

    ${props => props.theme.below.desktop`
        padding: 0 3rem;

        text-align: left;

        .button-circle {
            padding: 0 0 3rem 0;
        }
    `}

    ${props => props.theme.above.desktop`
        margin-bottom: 15rem;
    `}
`

const StyledVerticalSections = styled(VerticalSections)`
    ${props => props.theme.above.desktop`
        margin-bottom: 20rem;
    `}
`

const StyledBanner = styled(SectionBanner)`
    margin-bottom: 6rem;

    ${props => props.theme.above.desktop`
        margin-bottom: 15rem;
    `}
`

const NewPage = ({
    lang,
    className,
    items,
    sectionInView,
    inViewTreshold,
    data: {
        content_title,
        content_description,
        content_button_label,
        banner_image,
        second_description,
        second_title,
        second_button_label,
        vertical_sections,
        about_items,
    },
}) => {
    const [ref, wrapperInView] = useInView({
        threshold: 0.15,
        triggerOnce: true,
    })

    return (
        <Wrapper ref={ref} className={className} name="about">
            <InView
                treshold={inViewTreshold}
                onChange={inView => inView && sectionInView && sectionInView()}
            >
                <TitleWithButtonWrapper>
                    <Title
                        title={content_title.text}
                        description={content_description.text}
                        alignment="center"
                        text
                    />
                    <ButtonCircle
                        type="arrow"
                        direction="down"
                        label={content_button_label}
                        animation="after"
                        onClick={() => {
                            scroller.scrollTo("vertical-slides", {
                                duration: 1500,
                                delay: 100,
                                smooth: "easeInOutQuint",
                            })
                        }}
                    />
                </TitleWithButtonWrapper>
                <StyledVerticalSections data={vertical_sections} />
                <StyledBanner data={banner_image} showImageTitle />
                <TitleWithButtonWrapper>
                    <Title
                        title={second_title.text}
                        description={second_description.text}
                        alignment="center"
                        text
                    />
                    <ButtonCircle
                        type="arrow"
                        direction="down"
                        label={second_button_label}
                        animation="after"
                        onClick={() => {
                            scroller.scrollTo("usps", {
                                duration: 1500,
                                delay: 100,
                                smooth: "easeInOutQuint",
                            })
                        }}
                    />
                </TitleWithButtonWrapper>
                <AboutUsps lang={lang} data={about_items} />
            </InView>
        </Wrapper>
    )
}

export default NewPage

import React from 'react'
import styled from 'styled-components'
import { InView, useInView } from 'react-intersection-observer'
import { graphql } from 'gatsby'

import Seo from './../components/Layout/Seo'
import InstagramFeed from './../components/Instagram/InstagramFeed'
import NewAbout from './../components/About/NewPage'

const StyledAbout = styled(NewAbout)`
    border-bottom: none;
`

const AboutPage = ({
    data: {
        items,
        instagram,
        about: {
            data
        }
    },
    pageContext: {
        lang,
        langSlug
    }
}) => {
    return (
        <>
            <Seo
                title={data.seo_title}
                description={data.seo_description.text}
                keywords={data.seo_keywords}
                image={data.seo_image}
            />
            <NewAbout 
                lang={langSlug}
                data={data}
                items={items} 
            />
            <InstagramFeed 
                copy={instagram} 
            />
        </>
    )
}

export const QUERY_ABOUTPAGE = graphql`
    query AboutPage($id: String!, $lang: String!) {
        about: prismicAboutPage(id: { eq: $id }) {
            id
            lang
            ...AboutPageQuery
        }
        items: allPrismicAboutItem(filter: {lang: {eq: $lang}}) {
            edges {
                node {
                    uid
                    data {
                        about_title {
                            text
                        }
                        about_excerpt {
                            text
                        }
                        about_image {
                            image {
                                alt
                                fluid(maxWidth: 800) {
                                    ...GatsbyPrismicImageFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        instagram: prismicInstagramComponent(lang: { eq: $lang }) {
            id
            lang
            ...InstagramQuery
        }
    }
`

export default AboutPage

import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import VerticalTitle from "./VerticalTitle"

const Wrapper = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    max-height: 800px;

    overflow: hidden;
`

const StyledImage = styled(Img)`
    width: 100%;
    height: 100%;

    max-height: 800px;

    object-fit: cover;
`

const ImageTitle = styled.span`
    position: absolute;

    bottom: 5rem;
    left: 5rem;

    font-size: 1.25rem;
    line-height: 1;

    color: #fff;

    ${props => props.theme.below.desktop`
        display: none;
    `}
`

const Overlay = styled.div`
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 10rem;

    opacity: 0.75;

    background-blend-mode: hard-light;
    background-image: linear-gradient(
        180deg,
        hsla(0, 0%, 100%, 0),
        rgba(0, 0, 0, 0.25) 50%,
        rgba(0, 0, 0, 0.5)
    );
`

const SectionBanner = ({ data, className, showImageTitle }) => {
    return (
        <Wrapper className={className || ""}>
            <StyledImage fluid={data.fluid} alt={data.alt} />
            {showImageTitle && (
                <>
                    <Overlay />
                    <VerticalTitle title={data.alt} />
                    {/* <ImageTitle>{data.alt || "Add alt to image"}</ImageTitle> */}
                </>
            )}
        </Wrapper>
    )
}

export default SectionBanner

import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Image from "gatsby-image"

import Title from "./../Title"
import ButtonCircle from "./../Buttons/ButtonCircle"
import { generatePath } from "./../../utils/helpers"
import { DictionaryContext } from "./../../contexts/dictionary"

import Circle1 from "./../../graphics/circles/circle-1.svg"
import Circle2 from "./../../graphics/circles/circle-2.svg"
import Circle3 from "./../../graphics/circles/circle-3.svg"

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    width: 100%;
    max-width: 1440px;

    margin: 0 auto;

    ${props => props.theme.below.desktop`
        grid-template-columns: repeat(1, 1fr);
    `}

    ${props => props.theme.above.desktop`
        margin-bottom: 20rem;
    `}
`

const Item = styled.article`
    .title-wrapper {
        padding: 0;
    }

    ${props => props.theme.below.desktop`
        &:nth-of-type(3) {
            .title-wrapper {
                margin-bottom: 0;
            }
            
            .button-circle {
                padding: 0 0 6rem 3rem;
            }
        }
    `}

    ${props => props.theme.above.desktop`
        &:first-of-type {
            grid-column: 1 / 6;
            grid-row: 1;

            padding-left: 5rem;

            .inner {
                width: 57rem;
            }
            
            .circle {
                transform: translate(75%, 145%);
            }
        }

        &:nth-of-type(2) {
            padding-top: 50rem;
            
            grid-column: 8 / 13;
            grid-row: 1;

            .inner {
                width: 42rem; 
            }
            
            .circle {
                top: initial;
                right: initial;
                left: 0;
                bottom: 0;

                transform: translate(-50%, -205%);
            }
        }

        &:nth-of-type(3) {
            margin-top: -25rem;
            padding-left: 5rem;
            
            grid-column: 1 / 6;
            grid-row: 2;

            .inner {
                width: 57rem;
            }

            .title-wrapper {
                margin-bottom: 3rem;
            }
            
            .circle {
                transform: translate(25%, 15%);
            }
        }
    `}
`

const Inner = styled.div`
    position: relative;

    ${props => props.theme.below.desktop`
        .title-wrapper {
            padding: 3rem !important;
        }
    `}
`

const StyledImage = styled(Image)`
    margin-bottom: 3rem;
`

const CircleCss = css`
    width: 100%;

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    animation: rotating 5s linear infinite;

    ${props => props.theme.below.desktop`
        display: none;
    `}
`

const StyledCircle1 = styled(Circle1)`
    ${CircleCss}
`

const StyledCircle2 = styled(Circle2)`
    ${CircleCss}

    animation-duration: 20s;
`

const StyledCircle3 = styled(Circle3)`
    ${CircleCss}

    animation-duration: 20s;
`

const CircleWrapper = styled.div`
    position: absolute;

    top: 0;
    right: 0;

    z-index: 1;

    ${props => props.theme.below.desktop`
        display: none;
    `}
`

const CircleNumber = styled.span`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    font-size: 1.5rem;

    color: #fff;
`

const AboutUsps = ({ className, data, lang }) => {
    const dictionary = useContext(DictionaryContext)
    return (
        <Wrapper className={className} id="usps">
            {data.map((entry, i) => {
                return (
                    <Item key={i}>
                        <Inner className="inner">
                            <CircleWrapper className="circle">
                                <CircleNumber>{i + 1}</CircleNumber>
                                {i == 0 && <StyledCircle1 />}
                                {i == 1 && <StyledCircle2 />}
                                {i == 2 && <StyledCircle3 />}
                            </CircleWrapper>
                            <StyledImage fluid={entry.about_item_image.fluid} />
                            <Title
                                title={entry.about_item_title.text}
                                description={entry.about_item_description}
                                alignment="left"
                                html
                            />
                            {i == 2 && (
                                <ButtonCircle
                                    type="arrow"
                                    direction="right"
                                    label={dictionary.explore_the_collection}
                                    animation={"after"}
                                    to={generatePath(lang, `collection`)}
                                />
                            )}
                        </Inner>
                    </Item>
                )
            })}
        </Wrapper>
    )
}

export default AboutUsps

import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import stickybits from "stickybits"
import Img from "gatsby-image"

import "./styles.scss"
import VerticalTitle from "../VerticalTitle"

const VerticalSections = ({ data, className }) => {
    const imageWrapperRefs = useRef([])
    const containerRef = useRef(null)
    const imageRefs = useRef([])
    const contentRefs = useRef([])
    const titleRefs = useRef([])

    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        if (typeof window !== "undefined") {
            gsap.registerPlugin(ScrollTrigger)
        }

        stickybits(containerRef.current)

        let tlArr = []

        imageWrapperRefs.current.forEach((image, i) => {
            if (i != 0) {
                gsap.set(image, { alpha: 0.0 })
            }

            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: contentRefs.current[i],
                    start: "45%",
                    end: "45%",
                    toggleActions: "play none none reverse",
                },
                onStart: () => {
                    setCurrentIndex(i + 1)
                    console.log("on start")
                },
                onReverseComplete: () => {
                    setCurrentIndex(0)
                    console.log("on reverse complete")
                },
                paused: true,
            })

            if (imageWrapperRefs.current[i + 1]) {
                tl.to(
                    imageWrapperRefs.current[i + 1],
                    {
                        alpha: 1.0,
                        duration: 0.5,
                        ease: "expo.easeOut",
                    },
                    0.0
                )
            }

            tlArr.push(tl)
        })

        // return () => {
        //     tlArr.forEach(tl => {
        //         tl.kill()
        //     })
        // }
    }, [])

    return (
        <section
            className={`sticky vertical-slides ${className || ""}`}
            id="vertical-slides"
        >
            <div
                ref={containerRef}
                className={"vertical-slides__images-container"}
            >
                <div className={"vertical-slides__images-inner"}>
                    {data.map((entry, i) => {
                        console.log(entry)
                        return (
                            <div
                                ref={el => (imageWrapperRefs.current[i] = el)}
                                id={`image-${i}`}
                                className={`vertical-slides__image-wrapper`}
                                key={i}
                            >
                                <Img
                                    className={`vertical-slides__image`}
                                    alt={entry.section_image.alt}
                                    fluid={entry.section_image.fluid}
                                />
                                <VerticalTitle
                                    title={entry.section_image.alt}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={"vertical-slides__content-container"}>
                {data.map((entry, i) => (
                    <div
                        className={"vertical-slides__content-inner"}
                        ref={el => (contentRefs.current[i] = el)}
                        key={i}
                    >
                        <div className={"vertical-slides__content-wrapper"}>
                            <div style={{ position: "relative" }}>
                                <Img
                                    className={`vertical-slides__image vertical-slides__image--mobile`}
                                    fluid={entry.section_image.fluid}
                                />
                                <div className="is-mobile">
                                    <VerticalTitle
                                        title={entry.section_image.alt}
                                    />
                                </div>
                            </div>
                            <div className={"vertical-slides__content"}>
                                <h3 ref={el => (titleRefs.current[i] = el)}>
                                    {entry.section_title.text}
                                </h3>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: entry.section_description.html,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className={"vertical-slides__indicator"}>
                <div className={"vertical-slides__indicator-inner"}>
                    <span className={`link vertical-slides__indicator-label`}>
                        <span>{currentIndex + 1}</span> / {data.length}
                    </span>
                </div>
            </div>
        </section>
    )
}

export default VerticalSections
